@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;
@use '../styles/css_constant' as cssConstants;

$max-width: 1400px;

.mosey-layout {
    @media (min-width: cssConstants.$page_max_width) {
        .block-max-width {
            max-width: cssConstants.$block_max_width;
        }
    }

    .domain-page-blocks .block-max-width {
        @media (max-width: 1px) {
            max-width: 100%;
        }

        @media (min-width: 600px) {
            width: calc(11 / 12 * 100%);
        }

        @media (min-width: 960px) {
            width: calc(8 / 12 * 100%);
        }

        @media (min-width: 1280px) {
            width: calc(6 / 12 * 100%);
        }

        @media (min-width: 1920px) {
            width: calc(6 / 12 * 100%);
        }
    }

    .footer-max-width {
        max-width: $max-width;
        margin: auto;
    }
}
