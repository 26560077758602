@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;
@use '../styles/css_constant' as cssConstants;

// different styling and color changes for components from dds-components

// adjustments for navigation cards // https://design.domstol.no/987b33f71/p/0822fe-cards/b/404556
.navigation-card-wrapper {
    margin-top: ddsTokens.$dds-spacing-x1-5;
}

.navigation-card {
    .navigation-text {
        padding: ddsTokens.$dds-spacing-x0-75 ddsTokens.$dds-spacing-x1-5;
    }

    &.nav-card-margin {
        margin: ddsTokens.$dds-spacing-x1;
    }

    .navigation-icon {
        background-color: ddsTokens.$dds-color-surface-hover-default;
    }

    svg {
        vertical-align: text-bottom;
    }

    .navigation-icon-margin-right {
        margin-right: 15px;
    }
}

@media screen and (max-width: cssConstants.$mobil_max_width) {
    .navigation-card {
        width: 100%;
        text-align: center;

        &.nav-card-margin {
            margin: 0;
        }
    }
}

.card-element {
    display: flex;
    max-width: cssConstants.$card_max_width;

    &.blue {
        min-height: 106px;
        background-color: ddsTokens.$dds-color-surface-hover-default;

        .card-heading {
            display: flex;
            align-items: center;
        }

        p {
            word-break: break-word;
        }
    }

    &.white {
        min-height: 62px;

        p {
            hyphens: manual !important;
        }
    }

    &.filledDark {
        min-height: 62px;
        width: fit-content !important;
    }

    .card-element-container {
        justify-content: space-between;
        width: 100%;
        display: flex;
        align-items: center;

        .card-heading {
            margin: ddsTokens.$dds-spacing-x1 1px ddsTokens.$dds-spacing-x1 ddsTokens.$dds-spacing-x2;

            &.single-button {
                width: max-content !important;
                margin-right: 25px;
            }
        }

        .card-icon {
            margin: auto ddsTokens.$dds-spacing-x2 auto auto;
            color: ddsTokens.$dds-color-text-default;
        }

        @media (max-width: 577px) {
            .single-button {
                width: fit-content !important;
            }

            .card-heading {
                margin: ddsTokens.$dds-spacing-x1 1px ddsTokens.$dds-spacing-x1 ddsTokens.$dds-spacing-x1-5 !important;
            }

            .card-icon {
                margin: auto ddsTokens.$dds-spacing-x1-5 auto auto !important;
            }
        }

        @media (max-width: 577px) {
            &.filledDark svg {
                margin: auto 26px auto 10px !important;
            }
        }
    }
}

// listing of newspages

.news-listing-header {
    display: flex;
    margin: 18px 0 9px 0;
    justify-content: space-between;

    .news-listing-header-text-left {
        min-width: 20px;
    }

    .news-listing-header-icon-right {
        margin-left: 20px;
        color: ddsTokens.$dds-color-text-default !important;
        margin-top: 4px;

        &.interactive-darker {
            color: ddsTokens.$dds-color-text-default !important;

            svg {
                margin-right: 5px;
            }
        }
    }
}

.external-link {
    svg {
        position: relative;
        top: 2px;
        margin-left: 4px;
    }
}

#ingress .external-link svg {
    font-size: 22px !important;
    top: 3px !important;
}

.feedback-container .external-link svg {
    font-size: 13px !important;
    top: 2px;
    margin-left: 4px;
}

.narrow-heading {
    padding-left: 2px;
}

// Adjustment for pagination small screens
@media (max-width: 577px) {
    .pagination-adjustment nav ol {
        display: flex;
        flex-wrap: wrap;
    }
}
