@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;

.pop-button {
    background: none;
    border: none;
    padding: 0px;
    font: inherit;
    position: relative;
    color: ddsTokens.$dds-color-text-action-resting;
    border-bottom: 1px dashed ddsTokens.$dds-color-text-action-resting;
    cursor: pointer;
    height: 25px;
    user-select: text;

    svg {
        position: relative;
        top: -5px;
        color: ddsTokens.$dds-color-text-action-resting;
        font-size: 14px !important;
    }
}

.ingress-lead .pop-button {
    height: 32px;

    svg {
        font-size: 14px !important;
        top: -8px !important;
    }
}

h2 span button {
    height: 40px !important;

    svg {
        font-size: 18px !important;
        top: -10px !important;
    }
}

h3 span button {
    height: 30px !important;

    svg {
        top: -8px !important;
    }
}

.svg-icon-popover {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.popover-typography {
    p {
        font: ddsTokens.$dds-font-body-small;
        letter-spacing: ddsTokens.$dds-font-body-small-letter-spacing;
        margin-top: ddsTokens.$dds-font-body-large-paragraph-spacing;
        margin-bottom: ddsTokens.$dds-font-body-large-paragraph-spacing;
    }

    h2 {
        font: ddsTokens.$dds-font-heading-medium;
        letter-spacing: ddsTokens.$dds-font-heading-medium-letter-spacing;
    }
}
