@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;

.editorial-danger {
    margin-top: 10px;
    margin-bottom: 5px;
    background-color: ddsTokens.$dds-color-surface-danger-default;
}

.editorial-warning {
    margin-top: 10px;
    margin-bottom: 5px;
    background-color: ddsTokens.$dds-color-surface-warning-default;
}

.editorial-info {
    margin-top: 10px;
    margin-bottom: 5px;
    background-color: ddsTokens.$dds-color-surface-info-default;
}
