@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;

// it is not possible to use the List component from Elsa directly in xhtml containers.
// the lists made in xhtml fields will use this styling imported from ELSA
.mosey-layout {
    .xhtml-container,
    .xhtml-container-text-block,
    .xhtml-container-listoflinks-block,
    .xhtml-container-collapseable-list-block,
    .xhtml-container-teaserlist-block,
    .xhtml-container-youtube-block,
    .xhtml-container-vimeo-block,
    .xhtml-container-feedback {
        ul {
            padding-left: calc(32px - (1rem + 4px));
            color: ddsTokens.$dds-color-text-default;
            list-style: none;
            margin: 16px 0px;
            font: inherit;

            li:before {
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                position: absolute;
                top: calc(0.35rem);
                height: 1em;
                width: 1em;
                left: 0px;
                content: '';
                background-image: url("data:image/svg+xml,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='6.7085' cy='6.7085' r='2.625' fill='%230B0D0E'/%3e%3c/svg%3e");
            }

            li {
                position: relative;
                padding-left: calc(1em + 4px);
                display: list-item;
                list-style: none;

                ul > li > ul > li::before {
                    background-image: url("data:image/svg+xml,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.70817 9.33333C8.80225 9.33333 10.4998 7.50516 10.4998 5.25H2.9165C2.9165 7.50516 4.61409 9.33333 6.70817 9.33333Z' fill='%230B0D0E'/%3e%3c/svg%3e");
                }

                ul > li::before {
                    background-image: url("data:image/svg+xml,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='3.04346' y='6.08691' width='7.30435' height='1.82609' fill='%230B0D0E'/%3e%3c/svg%3e");
                }
            }

            ul > li::before {
                background-image: url("data:image/svg+xml,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='3.04346' y='6.08691' width='7.30435' height='1.82609' fill='%230B0D0E'/%3e%3c/svg%3e");
            }

            ul > ul > li::before {
                background-image: url("data:image/svg+xml,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.70817 9.33333C8.80225 9.33333 10.4998 7.50516 10.4998 5.25H2.9165C2.9165 7.50516 4.61409 9.33333 6.70817 9.33333Z' fill='%230B0D0E'/%3e%3c/svg%3e");
            }
        }

        ol {
            padding-left: 32px;

            li {
                font-variant-numeric: tabular-nums;

                ol > li {
                    list-style-type: lower-alpha;
                }

                ol > li > ol > li {
                    list-style-type: lower-roman;
                }
            }

            ol > li {
                list-style-type: lower-alpha;
            }

            ol > ol > li {
                list-style-type: lower-roman;
            }
        }
    }
}
