@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;

.tilsynsutvalget {
    .standard-page {
        padding-top: 100px !important;

        h1,
        #ingress {
            background-color: ddsTokens.$dds-color-brand-secondary-subtle;
            padding: 0 100vw ddsTokens.$dds-spacing-x1-5 100vw;
            margin: 0 -100vw;

            position: relative;
            z-index: 0;
        }

        #ingress {
            padding: 0 100vw 10px 100vw;
        }

        .ingress-lead {
            background-color: ddsTokens.$dds-color-brand-secondary-subtle;
            padding: ddsTokens.$dds-spacing-x1 100vw ddsTokens.$dds-spacing-x1 100vw;
            margin: 0 -100vw;
        }
    }

    @media screen and (min-width: 767px) {
        .breadcrumb-wrapper {
            nav {
                background-color: rgb(230, 237, 237);
                padding: ddsTokens.$dds-spacing-x2 100vw ddsTokens.$dds-spacing-x4 100vw;
                margin: -32px -100vw;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .standard-page {
            background: linear-gradient(180deg, rgb(230, 237, 237) 110px, white 0%);
        }
    }

    .language-not-available-message-wrapper {
        background-color: ddsTokens.$dds-color-brand-secondary-subtle;
        margin: 0 -100vw;
        padding: 0 100vw 12px 100vw;
    }

    .warning-content {
        background-color: ddsTokens.$dds-color-brand-secondary-subtle;
        padding: 0 100vw ddsTokens.$dds-spacing-x1-5 100vw;
        margin: 0 -100vw;
    }

    main {
        min-height: 80vh;
        position: relative;
    }

    #main-content-area {
        margin-top: ddsTokens.$dds-spacing-x2;
    }
}
