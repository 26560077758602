@use '../styles/css_constant' as cssConstants;

// should be the main container of each pagetype (gets smooth width when in mobile mode)
.page-container {
    width: auto;
}

.container {
    width: auto;
    padding-left: 0;
    padding-right: 0;
}

.supremeCourt {
    .container {
        max-width: inherit;
    }
}

.mosey-layout {
    @media (min-width: 768px) {
        .container {
            max-width: 1320px !important;
        }
    }

    @media (min-width: 576px) {
        .container {
            max-width: 1320px !important;
        }
    }

    .container {
        padding-left: 6rem;
        padding-right: 5rem;
    }

    @media (max-width: cssConstants.$nettbrett_max_width) {
        .container {
            padding-left: 2rem !important;
            padding-right: 2rem !important;
            margin: 0 !important;
        }
    }

    @media (max-width: cssConstants.$mobil_max_width) {
        .container {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            margin: 0 !important;
        }
    }
}

.custom-grid-container {
    margin-right: 0;
    margin-left: 0;
    gap: 0;
}
