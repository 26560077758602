/* Configure generic look & feel */
$enable-rounded: true;
$enable-responsive-font-sizes: true;
$font-family-sans-serif:
    'Barlow',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';

/* Configure grid */
$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 960px,
    lg: 1280px,
    xl: 1920px,
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1410px,
);
$sizes: (
    20: 20%,
    80: 80%,
);

.mosey-layout {
    ::selection {
        color: rgb(11, 13, 14);
        background-color: rgb(255, 230, 223);
    }
}
