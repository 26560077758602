@use '../styles/css_constant' as *;

.d-cover {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.placeholder {
    border: 1px solid #999;
    background-color: #ccc;
    color: #999;
    text-align: center;
    vertical-align: middle;
}

@media screen and (max-width: $nettbrett_max_width) {
    .visually-hidden-main {
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
        position: absolute !important;
    }
}

.custom-visually-hidden {
    display: none;
}

.social svg {
    width: 16px !important;
    height: 16px !important;
}
