@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;
@use './css_constant' as ccsConstants;

.breadcrumb-wrapper {
    margin-top: ddsTokens.$dds-spacing-x2;
    margin-bottom: ddsTokens.$dds-spacing-x4;
    position: absolute;

    &.relative {
        position: relative;
    }

    &.banner {
        margin-top: ddsTokens.$dds-spacing-x1;
        margin-bottom: ddsTokens.$dds-spacing-x2;
    }

    a {
        text-underline-offset: 5px;
    }

    .link-width {
        display: inline-block;

        a {
            font: ddsTokens.$dds-font-body-medium;
        }
    }
}

.breadcrumb-margin {
    padding-top: 121px;

    &.banner {
        padding-top: 73px;
    }

    &.blue {
        background-clip: content-box, padding-box;
        background-image:
            linear-gradient(to bottom, white 0%, white 100%), linear-gradient(to bottom, #eaf2f7 0%, #eaf2f7 100%);
    }
}

@media screen and (max-width: ccsConstants.$nettbrett_max_width) {
    .breadcrumb-wrapper {
        margin-bottom: ddsTokens.$dds-spacing-x2;
    }

    .breadcrumb-margin {
        padding-top: 90px;
    }
}
