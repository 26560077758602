@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;
@use '../styles/css_constant' as cssConstants;

.mosey-layout {
    // place a div with this class where you want to have a horizontal line
    .grid-horizontal {
        margin-top: ddsTokens.$dds-spacing-x0-5;
        margin-left: -65px;
        border-top: 1px solid ddsTokens.$dds-color-surface-inverse-selected;
    }

    // create a div with this class with all elements you want to have vertical grid on inside this div.
    // Adjust height of grid in grid-vertical:after
    .grid-vertical {
        position: relative;
    }

    .grid-vertical:after {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: -15px;
        top: 10px;
        left: -20px;
        border-left: 1px solid ddsTokens.$dds-color-surface-inverse-selected;
    }

    // remove the grid if the size of the page is smaller than 600px
    @media (max-width: cssConstants.$nettbrett_max_width) {
        .grid-vertical:after {
            content: none !important;
        }
        .grid-horizontal {
            border-top: 0 !important;
        }
    }

    // Adjust the height of the vertical grid for different pages based on figma
    .nar-gar-rettssaken-grid::after {
        bottom: -70px;
        left: -30px;
    }

    .finnmarkskommisjonen::after {
        bottom: 0px;
    }

    .enkelt-domstol-grid:after {
        top: -50px;
        bottom: 5px;
    }

    .start-page:after {
        bottom: 0px;
    }

    .find-court-grid:after {
        left: -30px;
    }

    .jordskifteretten:after {
        bottom: -5px;
        top: -41px;
        left: -30px;
    }

    .jordskifteretten-header:after {
        bottom: -16px;
        left: -30px;
    }
}
